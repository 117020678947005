// React
import React, { useState } from 'react';

// Libraries
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

// CSS
import './css/main.css';

// Utilities
import { pickRandom } from './utilities';

// Icons
import { FaChevronRight } from 'react-icons/fa';

// Themes
import themes from './themes';

// FormSchema
const FormSchema = Yup.object().shape({
  fullName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Full Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  message: Yup.string()
    .min(10, 'Message too Short! (10-150 chars)')
    .max(150, 'Too Long!')
    .required('Message is required'),
});

const App = ()=> {

  const theme = pickRandom(themes);

  const [ emailSent, setEmailSent ] = useState(false);
  const [ formError, setFormError ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);

  return (
    <main>

      <div className="left">

        <nav className="main-navbar">
          <a href="/" className="logo-link">
            <img src="/images/landaextracts_logo.svg" alt="L&amp;A Extracts" />
          </a>
        </nav>

        <div className="content">
          <h1 className="title">
            Discover<br/> your flavor
          </h1>
          <h2 className={`subtitle1 ${theme.className}`}>
            With the right flavor, food turns into an orchestra
          </h2>
          <p className="body1">
            Our focus is to deliver a range of the absolute best flavor extracts. Whether you’re a recognised producer in the food industry or a novice baker, we can help you discover the flavor you need to increase the potential of your products.
          </p>
          <h2 className={`subtitle2 ${theme.className}`}>
            Popular supplied products
          </h2>
          <div className="products-lists">
            <ul className="products-list">
              <li>Ascorbic Acid</li>
              <li>Pectin</li>
              <li>Antocyanin</li>
            </ul>
            <ul className="products-list">
              <li>Apple Flavor</li>
              <li>Mango Flavor</li>
              <li>Litchi Flavor</li>
            </ul>
            <ul className="products-list">
              <li>Strawberry Flavor</li>
              <li>Cranberry Flavor</li>
            </ul>
          </div>

        </div>

        <footer className="main-footer">Copyright &copy; 2021 L&amp;A Extracts (PTY) Ltd. All rights reserved.</footer>

      </div>

      <div className={`right ${theme.className}`}>
        <div className="contact-us">
          <h5 className="title">Contact Us</h5>

          { !emailSent &&
            <Formik
              initialValues={{
                fullName: '',
                email: '',
                message: '',
              }}
              validationSchema={FormSchema}
              onSubmit={async ({ fullName, email, message }) => {

                setFormError(false);
                setIsLoading(true);

                try {

                  const data = await fetch("/api/sendEmail", {
                    "method": "POST",
                    "headers": { "content-type": "application/json" },
                    "body": JSON.stringify({ fullName, email, message })
                  });

                  const { message:responseMessage } = await data.json();
                  setEmailSent(responseMessage);

                } catch (error) {
                  setFormError(error.message);
                }

                setIsLoading(false);

              }}
            >
              {({ errors, touched }) => {
                return (
                  <Form>

                    <label htmlFor="#fullName" className="input-label" required>
                      Full Name
                    </label>
                    { errors.fullName && touched.fullName ? (
                      <div className="input-error">{errors.fullName}</div>
                    ) : null}
                    <Field name="fullName" type="text" className="input-label" />

                    <label htmlFor="#fullName" className="input-label" required>
                      Email
                    </label>
                    {errors.email && touched.email ? <div className="input-error" >{errors.email}</div> : null}
                    <Field name="email" className="input-label" type="email" />

                    <label htmlFor="#fullName" className="input-label" required>
                      Message
                    </label>
                    { errors.message && touched.message ? (
                      <div className="input-error">{errors.message}</div>
                    ) : null}
                    <Field name="message" className="input-label" component="textarea" />

                    <button className={`submit-btn ${theme.className}`} type="submit" disabled={isLoading}>
                      {isLoading ? '...loading' : <>Submit <FaChevronRight /></> }
                    </button>

                  </Form>
                )
              }}
            </Formik>
          }

          { emailSent &&
            <div className="form-success">
              Message sent! We'll be in contact soon.
            </div>
          }

          { formError &&
            <div className="form-error" style={{ marginTop: '1rem', textAlign: 'center' }}>
              Something went wrong.<br />Please try again later.
            </div>
          }

        </div>
      </div>
      
    </main>
  );
}

export default App;
