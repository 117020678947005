const themes = [
  {
    className: 'orange',
    image: '/images/right-background-orange.jpg',
  },
  {
    className: 'green',
    image: '/images/right-background-green.jpg',
  },
  {
    className: 'brown',
    image: '/images/right-background-brown.jpg',
  },
  {
    className: 'purple',
    image: '/images/right-background-purple.jpg',
  },
]

export default themes;